<template>
	<div class="formatAGlobalContainer">
		<zoom-center-transition group v-if="!displayResult">
			<v-row v-for="(questionA, index) in questions.slice(countFormat1 - 1, countFormat1)" :key="index">
				<v-row :id="'full-formatAContainer' + questionA._id" style="margin-top: 50px">
					<v-col md="4" offset-md="1" sm="12" cols="12">
						<div class="formatAContainerQuestion" :id="'formatAContainerQuestion' + questionA._id">
							<div class="ortho-align-center">
								<h5 class="mb-4 text-uppercase ortho-text-gray">
									Question - {{ countFormat1 }}/{{ questions.length }}
								</h5>
								<div class="ortho-description mb30">
									<v-btn class="indicator-section" depressed>
										<span>Partie entrainements</span>
									</v-btn>
								</div>
								<div class="ortho-description pt20 mb10">
									<span>{{ questionA.action }}</span>
								</div>
								<div class="ortho-questions ortho-bold libelle formatA-question mb20" :id="'libelle' + questionA._id">
									<span>{{ questionA.libelle }}</span>
								</div>
							</div>
							<v-col md="12" sm="12" cols="12" v-for="(answer, indexAnswer) in questionA.answers" v-bind:key="answer">
								<div class="ortho-align-center">
									<div
										class="hvr-grow ortho-questions-answers ortho-questions-answers-null"
										v-bind:ref="questionA._id"
										v-bind:data-id="questionA._id"
										v-bind:data-answer="indexAnswer"
										:class="{
											'ortho-questions-answers-correct-full':
												userAnswer != null && indexAnswer == questionA.good_answer,
											'ortho-questions-answers-correct':
												userAnswer == indexAnswer && indexAnswer == questionA.good_answer,
											'ortho-questions-answers-incorrect-border':
												userAnswer == indexAnswer && indexAnswer != questionA.good_answer,
											'ortho-questions-answers-incorrect': userAnswer != null && indexAnswer != questionA.good_answer,
											'ortho-disable': isDisabled,
										}"
										@click="answerQuestionFormatA(questionA, indexAnswer)"
									>
										{{ answer }}
									</div>
								</div>
							</v-col>
						</div>
					</v-col>
					<v-col
						md="4"
						offset-md="1"
						sm="12"
						cols="12"
						class="formatACoursContainer pl-3 pr-3 pl-md-0 pr-md-0"
						:class="{
							pb20: $vuetify.breakpoint.smAndDown,
							pt20: $vuetify.breakpoint.mdAndUp,
						}"
					>
						<PanelsQuestionsRules
							:id="'panel-' + questionA._id"
							:question="questionA"
							:message="bravo"
							:responded="responded"
							:allQuestions="questions"
							:count="countFormat1"
							@ChangeTrainingQuestion="onChangeTrainingQuestion()"
							@goToResult="onGoToResult()"
						/>
					</v-col>
				</v-row>
			</v-row>
		</zoom-center-transition>
		<ResultQuestionsRules v-else :answersGiven="answersGiven" />
	</div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import PanelsQuestionsRules from './PanelsQuestionsRules';
import ResultQuestionsRules from './ResultQuestionsRules';
import soundCorrect from '@/assets/sound/goodAnswer.mp3';
import soundError from '@/assets/sound/wrongAnswer.mp3';

export default {
	name: 'QuestionsRules',
	props: {
		questions: Array,
	},
	components: {
		PanelsQuestionsRules,
		ResultQuestionsRules,
	},
	data() {
		return {
			storageKeySound: 'acceptSound',
			countFormat1: 1,
			bravo: '',
			responded: false,
			userAnswer: null,
			isDisabled: false,
			displayResult: false,
			answersGiven: [],
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
	methods: {
		answerQuestionFormatA(question, answer) {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			var audioCorrect = new Audio(soundCorrect);
			var audioWrong = new Audio(soundError);
			this.userAnswer = answer;
			this.isDisabled = true;
			let answerClicked = event.currentTarget.dataset.answer;
			this.responded = true;
			this.answersGiven.push({
				question_id: question._id,
				answer: question.answers[answerClicked],
				is_good_answer: answerClicked == question.good_answer,
			});
			if (answerClicked == question.good_answer) {
				if (cookie == null) {
					audioCorrect.play();
				}
				this.bravo = '👍 Top, tu maîtrises !';
			} else {
				if (cookie == null) {
					audioWrong.play();
				}
				this.bravo = '😯 Pas facile celle-là...';
			}
			//scroll to the next question, 1s delay between clic and scroll
			setTimeout(function () {
				VueScrollTo.scrollTo('#panel-' + question._id, {
					offset: (VueScrollTo.offset = -80),
				});
			}, 1000);
		},
		onChangeTrainingQuestion() {
			this.userAnswer = null;
			this.isDisabled = false;
			this.responded = false;
			this.bravo = '';
			this.countFormat1++;
		},
		onGoToResult() {
			this.displayResult = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.formatAGlobalContainer {
	padding-left: 10%;
	padding-right: 10%;
}
.formatA-question {
	font-size: 30px;
}
@media screen and (max-width: 600px) {
	.formatAGlobalContainer {
		padding-left: 0% !important;
		padding-right: 0% !important;
	}
}
</style>
